import Button, { ButtonProps } from '~/components/interactive/Button';
import { NavLink } from '@remix-run/react';
import IconLoading from '~/components/icons/streamline/line/IconLoading';

export type NavigationButtonProps = Omit<ButtonProps, 'onClick' | 'type'> & {
  to: string;
  popup?: boolean;
  download?: string;
};

export default function NavigationButton({ to, popup, icon, ...props }: NavigationButtonProps) {
  return (
    <NavLink to={to} download={props.download} viewTransition>
      {({ isPending }) => (
        <Button
          {...props}
          icon={isPending ? <IconLoading /> : icon}
          disabled={props.disabled || isPending}
          type="button"
          onClick={(e) => {
            if (props.download) {
              return;
            }

            if ((to.includes('http') && popup !== false) || popup) {
              window.open(to, '_blank');

              e.preventDefault();

              return;
            }
          }}
        />
      )}
    </NavLink>
  );
}
